body {
  overflow-x: hidden; /*to remove scrollbar-X*/
}
html {
  width: 100vw;
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*antd input css*/
:where(.css-dev-only-do-not-override-sk7ap8).ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 2;
  list-style: none;
  font-family: lato;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #808080;
  border-radius: 4px;
  transition: all 0.2s;
}

/*Password input css*/
:where(.css-dev-only-do-not-override-sk7ap8).ant-input-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 2;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #808080;
  border-radius: 4px;
  transition: all 0.2s;
}

/*antd select*/
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 38px;
  padding: 3px 11px;
  border: 1px solid #777777;
  border-radius: 4px;
}

/* Antd Collapse Header */

.ant-collapse-header-text {
  font-family: Montserrat-SemiBold;
  font-size: 1.125rem;
  color: #212121;
}

.ant-collapse-content-box {
  font-family: Montserrat-Regular;
  font-size: 1rem;
  color: #212121;
}

.ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  margin-top: -1rem;
}

.cm-img-class {
  height: 100%;
  width: 100%;
}

.br4 {
  border-radius: 4px;
}

.mb1 {
  margin-bottom: 1rem;
}

.hunperwidth {
  width: 100%;
}

.divider-horizontal {
  border: 1px solid rgb(119, 119, 119, 50%);
  width: 100%;
  margin: 5px;
}
