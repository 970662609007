@font-face {
  font-family: lato;
  src: url(../../assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: latoBold;
  src: url(../../assets/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: josefinBold;
  src: url(../../assets/fonts/JosefinSans-Bold.ttf);
}

@font-face {
  font-family: josefinSans;
  src: url(../../assets/fonts/JosefinSans-Regular.ttf);
}

.head-navbar {
  text-align: center;
  background: #154e36;
  color: white;
}

.main-navbar {
  background: #305e44;
  color: #305e44;
}
.mobile-menu .icon {
  color: #305e44; /* Default color */
}

@media (max-width: 899px) {
  .mobile-menu .icon {
    color: white; /* Color for screens smaller than 768px */
  }
}
.navbar-menu {
  overflow: hidden;
  margin: auto;
  text-align: center;
  padding: 10px;
}

.navbar-menu a {
  display: inline;
  padding: 0px 15px 0px 15px;
}

.nav-links-active {
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
  transition: all 0.2s ease-out;
  font: normal normal 18px josefinSans;
}

.services-nav-links-active {
  color: #154e36;
  border-bottom: 2px solid #154e36;
  transition: all 0.2s ease-out;
  font: normal normal 18px josefinSans;
}

.nav-links {
  display: inline-block; /* for media query only change display: block*/
  color: #f2f2f2;
  text-decoration: none;
  padding: 0px 10px;
  font: normal normal 18px josefinSans;
}

.services-nav-links {
  display: inline-block; /* for media query only change display: block*/
  color: #305e44;
  text-decoration: none;
  font-size: 17px;
  padding: 0px 5px;
  font: normal normal 18px josefinSans;
}

.nav-border {
  border-left: 1.5px solid #f2f2f2;
}

/*antd button css*/
.main-navbar :where(.css-dev-only-do-not-override-sk7ap8).ant-btn {
  text-decoration: none;
  background-color: #ffffff;
  border-color: #d9d9d9;
  color: #305e44;
  font-weight: 600;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 50px;
  font-size: 14px;
  height: 34px;
  padding: 5px 30px;
}

/*antd badge css*/
:where(.css-dev-only-do-not-override-sk7ap8).ant-badge .ant-badge-count {
  z-index: auto;
  min-width: 14px;
  height: 14px;
  color: #ffffff;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  text-align: center;
  background: #eba821;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #eba821;
  transition: background 0.2s;
}

.cart {
  display: inline-block;
  font-size: 18px;
  color: #305e44;
}

/* .menu {
  float: right;
  color: #f2f2f2;
  font-size: 25px;
} */

.close {
  float: right;
  color: #f2f2f2;
  font-size: 25px;
}

@media screen and (max-width: 1300px) and (min-width: 962px) {
  .menu-icon {
    display: none;
  }

  :where(.css-dev-only-do-not-override-26rdvq) a {
    display: inline-block; /* for media query only change display: block*/
    color: #f2f2f2;
    padding: 12px 24px;
    text-decoration: none;
    font-size: 17px;
  }
}

@media screen and (max-width: 960px) {
  .navbar-menu a {
    display: block;
    padding: 0px 0px 30px 0px;
  }

  .nav-border {
    border-left: none;
  }

  .nav-links {
    border-bottom: none;
  }

  .nav-links-active {
    border-bottom: none;
  }

  .navbar-menu-close {
    display: none;
  }
}

@media screen and (max-width: 460px) {
  .logo-img-size {
    width: 100%;
  }
}

.menu-responsive {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.menu-responsive .desktop-menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.menu-responsive .desktop-menu .menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  justify-content: space-between;
  flex-wrap: wrap;
}
.menu-responsive .menu .menu-item {
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  padding: 0px 5px 0px 5px;
}

.menu-item a {
  color: #ffffff;
}

.menu-item a.active {
  text-decoration: underline;
  color: #ffffff;
}

.divider {
  height: 30px;
  width: 1px;
  background-color: #ffffff;
}

.menu-item .ant-badge .ant-badge-count {
  right: 2px;
}
.mobile-menu {
  display: none;
}
@media screen and (max-width: 900px) {
  .menu-responsive {
    width: 100%;
  }

  .desktop-menu {
    display: none !important;
  }
  .mobile-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-right: 20px;
    min-height: 60px;
    position: relative;
  }

  .mobile-menu .menu {
    position: absolute;
    width: 100%;
    background-color: #305e44;
    height: 350px;
    z-index: 1000;
    top: 40px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .menu .divider {
    display: none;
  }
}


.menu-item a:hover{
  padding: 5px;
  border: 1px solid white;
  border-radius: 5px;
}

/* .menu-item-profile-icon a{
  padding: 0px;
  border: 1px solid white;
  border-radius: 50px;
} */