@font-face {
  font-family: lato;
  src: url(../../assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: latoBold;
  src: url(../../assets/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: josefinBold;
  src: url(../../assets/fonts/JosefinSans-Bold.ttf);
}

@font-face {
  font-family: josefinSans;
  src: url(../../assets/fonts/JosefinSans-Regular.ttf);
}

@font-face {
  font-family: josefinSemiBold;
  src: url(../../assets/fonts/JosefinSans-SemiBold.ttf);
}

@font-face {
  font-family: RomanRegular;
  src: url(../../assets/fonts/LuxuriousRoman-Regular.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: Montserrat-Regular;
  src: url(../../assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Montserrat-Medium;
  src: url(../../assets/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url(../../assets/fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: Montserrat-Bold;
  src: url(../../assets/fonts/Montserrat-Bold.ttf);
}

.appointment-container-box {
  box-shadow: 0px 0px 16px rgba(40, 81, 163, 0.24);
  border-radius: 0.5rem;
  padding: 1.5rem 1.5rem;
  background-color: #fff;
}

.appointment-container-box .ant-form-vertical .ant-form-item-label > label {
  font-family: lato;
  font-size: 0.875rem;
  color: #1c1c1c;
}

.estimate-price-container {
  box-shadow: 0px 0px 16px rgba(40, 81, 163, 0.24);
  border-radius: 0.5rem;
  padding: 2rem 3rem;
  background-color: #fff;
}

.estimate-price-container .ant-form-vertical .ant-form-item-label > label {
  font-family: Montserrat-Medium;
  font-size: 1.125rem;
  color: #212121;
}

.estimate-price-container .ant-input:placeholder-shown {
  font-family: Montserrat-Regular;
  font-size: 1rem;
  color: #777777;
}

.services-title {
  font-family: latoBold;
  font-size: 1.125rem;
  color: #2c2c2c;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-select:not(
    :where(.css-dev-only-do-not-override-26rdvq).ant-select-customize-input
  )
  .ant-select-selector {
  position: relative;
  background-color: #ffffff;
  border: 1.5px solid #a39c9c;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

:where(.css-dev-only-do-not-override-26rdvq).ant-select .ant-select-arrow {
  display: flex;
  align-items: center;
  color: #a39c9c;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  inset-inline-start: auto;
  inset-inline-end: 11px;
  height: 12px;
  margin-top: -6px;
  font-size: 13px;
  pointer-events: none;
}

.people-count-decr {
  border: 1.3px solid #a39c9c;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  /* font: normal normal 13px lato;
  color: #000; */
}

.people-count-incr {
  border: 1.3px solid #a39c9c;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  /* font: normal normal 13px lato;
  color: #000; */
}

.count-box {
  border: 1.3px solid #a39c9c;
  border-radius: 0;
  padding: 5px 0;
  font-family: lato;
  font-size: 1rem;
  color: #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border: 1.5px solid #a39c9c;
  border-radius: 6px;
  transition: all 0.2s;
}

.ant-radio-wrapper span.ant-radio + * {
  font-family: Poppins-Regular;
  font-size: 14px;
  color: #1c1c1c;
}

.ant-radio-wrapper .ant-radio-checked::after {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #305e44;
  border-radius: 50%;
  visibility: hidden;
  animation-name: css-dev-only-do-not-override-sk7ap8-antRadioEffect;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  content: "";
}

.ant-radio-wrapper .ant-radio-inner::after {
  box-sizing: border-box;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  display: block;
  width: 36px;
  height: 36px;
  margin-block-start: -18px;
  margin-inline-start: -18px;
  background-color: #305e44;
  border-block-start: 0;
  border-inline-start: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: "";
}

.ant-radio-wrapper .ant-radio-inner {
  box-sizing: border-box;
  position: relative;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: block;
  width: 20px;
  height: 20px;
  background-color: #ffffff !important;
  border-color: #a39c9c;
  border-style: solid;
  border: 1.5px solid #a39c9c;
  border-radius: 50%;
  transition: all 0.2s;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #305e44;
}

.ant-radio-wrapper .ant-radio:hover .ant-radio-inner {
  border-color: #305e44;
}

.ant-picker,
.ant-picker:hover,
.ant-picker-focused {
  border: 1px solid #777777;
  border-radius: 4px;
  box-shadow: none;
}

.ant-picker .ant-picker-input > input::placeholder {
  font-family: lato;
  font-size: 1rem;
  color: #1c1c1c;
}

.ant-picker .ant-picker-input > input {
  color: #1c1c1c;
  font-size: 1rem;
  font-family: lato;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-picker .ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-inline-start: 4px;
  color: black;
  line-height: 1;
  pointer-events: none;
}

.pricing-note {
  font-family: lato;
  font-size: 0.875rem;
  color: #1c1c1c;
  margin-top: 0.5rem;
}

.estimate-price-btn-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
}

.viewall-btn {
  background-color: #ffffff;
  border-color: #305e44;
  color: #305e44;
  font-weight: 600;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 50px;
  font-family: Montserrat-Bold;
  font-size: 1rem;
  height: 2.5rem;
  padding: 0 3.125rem;
}

.req-btn {
  background-color: #305e44;
  border-color: #305e44;
  color: #ffffff;
  font-weight: 600;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 50px;
  font-size: 14px;
  height: 34px;
  padding: 5px 30px;
}

.estimate-price-heading {
  font-family: Montserrat-Medium;
  font-size: 1.125rem;
  color: #212121;
}

.link-color {
  font-family: Montserrat-Bold;
  font-size: 1.125rem;
  color: #154e36;
}

.price-estimate-box {
  border: 2px solid #305e44;
  border-radius: 8px;
  padding: 1rem;
}

.price-estimate-div {
  font-family: Montserrat-Bold;
  font-size: 1.25rem;
  color: #212121;
}

.divider {
  border-block-start: 1.5px solid rgba(0, 0, 0, 0.25);
}

.sub-heading-style-A {
  font-family: Montserrat-Medium;
  font-size: 1rem;
  color: #212121;
  text-align: left;
}

.sub-heading-style-B {
  font-family: Montserrat-SemiBold;
  font-size: 1rem;
  color: #212121;
  text-align: right;
}

.sub-heading-style-C {
  font-family: Montserrat-Bold;
  font-size: 1rem;
  color: #212121;
  text-align: right;
}

.estimate-note {
  font-family: Montserrat-SemiBold;
  font-size: 1rem;
  color: #305e44;
}

.book-appoint-btn {
  background-color: #305e44;
  color: #ffffff;
  font-weight: 600;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 50px;
  font-family: Montserrat-Bold;
  font-size: 1rem;
  height: 2.5rem;
  padding: 0 3.125rem;
}
.ant-btn-default:disabled {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.amount-subtraction-text {
  font-family: Montserrat-Regular;
  font-size: 0.875rem;
  color: #305e44;
  line-height: 1rem;
  width: 50%;
  margin-top: 0.5rem;
}

.ant-breadcrumb-link {
  font-family: Montserrat-Bold;
  font-size: 1rem;
  color: #212121;
}

.ant-breadcrumb-link a {
  font-family: Montserrat-Medium;
  font-size: 1rem;
  color: #212121;
}

@media screen and (max-width: 1300px) and (min-width: 962px) {
  .price-estimate-wrapper {
    overflow-x: scroll;
    width: 100%;
  }
}

.price-estimate-wrapper {
  overflow-x: scroll;
  width: 100%;
}

/*add to cart start*/
:where(.css-dev-only-do-not-override-26rdvq).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #305e44;
  border-color: #305e44;
}
/*add to cart end*/

/*payment css start*/

.large-product-image-container {
  border-radius: 0.5rem;
  padding: 2rem 3rem;
  text-align: center;
  background-color: rgb(228, 228, 228);
}

/*Address start*/

.added-address-container {
  border: 1px solid #305e44;
  border-radius: 6px;
  width: 700px;
  height: auto;
  min-height: 100px;
  padding: 1rem;
}

.save-name-text {
  font-family: Montserrat-Bold;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.address-text {
  font-family: Montserrat-Regular;
  font-size: 1rem;
}

.addnew-address-container {
  border: 1px solid #305e44;
  border-radius: 6px;
  width: 700px;
  height: auto;
  cursor: pointer;
  padding: 1rem;
}

.delivery-address .added-address-container.active {
  border: 2px solid #5ef408;
}
/*Add address end*/

/*customer profile start*/
.customer-profile-heading {
  font-family: Montserrat-SemiBold;
  font-size: 2rem;
  color: #212121;
}

.customer-btmborder {
  border-bottom: 2px solid #f7aa1a;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  font-size: 1rem;
  font-weight: bold;
  color: #212121;
  text-shadow: 0 0 0.25px currentcolor;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #f7aa1a;
  pointer-events: none;
}

.ant-radio-wrapper span.ant-radio + * {
  font-family: Poppins-Regular;
  font-size: 14px;
  color: #1c1c1c;
}

.ant-radio-wrapper .ant-radio-checked::after {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #305e44;
  border-radius: 50%;
  visibility: hidden;
  animation-name: css-dev-only-do-not-override-sk7ap8-antRadioEffect;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  content: "";
}

.ant-radio-wrapper .ant-radio-inner::after {
  box-sizing: border-box;
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  display: block;
  width: 36px;
  height: 36px;
  margin-block-start: -18px;
  margin-inline-start: -18px;
  background-color: #305e44;
  border-block-start: 0;
  border-inline-start: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: "";
}

.ant-radio-wrapper .ant-radio-inner {
  box-sizing: border-box;
  position: relative;
  inset-block-start: 0;
  inset-inline-start: 0;
  display: block;
  width: 20px;
  height: 20px;
  background-color: #ffffff !important;
  border-color: #a39c9c;
  border-style: solid;
  border: 1.5px solid #a39c9c;
  border-radius: 50%;
  transition: all 0.2s;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #305e44;
}

.ant-radio-wrapper .ant-radio:hover .ant-radio-inner {
  border-color: #305e44;
}

.ant-picker,
.ant-picker:hover,
.ant-picker-focused {
  border: 1px solid #777777;
  border-radius: 4px;
  box-shadow: none;
}

.ant-picker .ant-picker-input > input::placeholder {
  font-family: lato;
  font-size: 1rem;
  color: #1c1c1c;
}

.ant-picker .ant-picker-input > input {
  color: #1c1c1c;
  font-size: 1rem;
  font-family: lato;
}

.profile-save-btn {
  background-color: #305e44;
  color: #ffffff;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 6px;
  font-family: Montserrat-Bold;
  font-size: 1rem;
  height: 2.5rem;
  padding: 0 4rem;
}

.profile-cancel-btn {
  background-color: #ffffff;
  color: #305e44;
  border: solid 1px #305e44;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  font-family: Montserrat-Bold;
  font-size: 1rem;
  height: 2.5rem;
  padding: 0 4rem;
}
/*my profile end*/

.package-plan-select label {
  width: 100%;
}
