@font-face {
  font-family: lato;
  src: url(../../assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: latoBold;
  src: url(../../assets/fonts/Lato-Bold.ttf);
}

@font-face {
  font-family: josefinBold;
  src: url(../../assets/fonts/JosefinSans-Bold.ttf);
}

@font-face {
  font-family: josefinSans;
  src: url(../../assets/fonts/JosefinSans-Regular.ttf);
}

@font-face {
  font-family: josefinSemiBold;
  src: url(../../assets/fonts/JosefinSans-SemiBold.ttf);
}

@font-face {
  font-family: RomanRegular;
  src: url(../../assets/fonts/LuxuriousRoman-Regular.ttf);
}

.landing-container h2 {
  font: normal normal 45px josefinSemiBold;
  text-align: center;
}

.landing-container div img,
.ourStudio .decorative img {
  width: 100%;
}

:where(.css-dev-only-do-not-override-26rdvq).ant-carousel
  .slick-dots
  li
  button {
  /* position: relative;
    display: block;
    border: 1px solid #EBA821;
    border-radius: 20px; */
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #ffffff;
  border: 0;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s;
}

.carousel-arrow {
  font-size: 13px;
  margin-left: -33px;
  color: white;
  background: #eba821;
  padding: 8px 10px;
  border-radius: 3px;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-carousel
  .slick-dots
  li.slick-active {
  width: 17px;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  /* margin-inline: 4px; */
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.3s;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-carousel
  .slick-dots
  li.slick-active
  button {
  background: #f7aa1a;
  opacity: 1;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-carousel
  .slick-dots
  li
  button {
  position: relative;
  display: block;
  width: 70%;
  height: 11px;
  padding: 0;
  color: transparent;
  font-size: 0;
  /* background: #ffffff; */
  border: 1.5px solid #f7aa1a;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  /* opacity: 0.3; */
  transition: all 0.3s;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-carousel .slick-dots {
  position: absolute;
  margin: auto;
  /* width: 50%; */
  /* inset-inline-end: 0; */
  /* bottom: 0; */
  /* inset-inline-start: 0; */
  z-index: 15;
  display: flex !important;
  justify-content: center;
  padding-inline-start: 0;
  /* background: #ffffff; */
  padding: 8px 0px 15px 0px;
  border-radius: 23px;
}

.subtitle-heading {
  font: bold 35px RomanRegular;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-btn-default {
  background-color: #ffffff;
  border-color: #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 50px;
}

/* .carousel-icons {
    display: block;
    color: #fff;
    font-size: 40px;
    background: #EBA821;
  } */

.landing-container .services-box {
  padding: 0px 0px 0px 50px;
}

.CartStyle
  :where(.css-dev-only-do-not-override-sk7ap8).ant-card
  .ant-card-body {
  padding: 24px;
  border-radius: unset;
  background: unset;
  cursor: unset;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-card .ant-card-body {
  padding: 24px;
  border-radius: 0 0 8px 8px;
  background: #eba821;
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-card .ant-card-meta-title {
  color: #ffff;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: normal normal 16px josefinSemiBold;
}

.landing-container .req-btn {
  background-color: #305e44;
  border-color: #305e44;
  color: #ffffff;
  font-weight: 600;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 50px;
  font-size: 14px;
  height: 34px;
  padding: 5px 60px;
}

.landing-container .viewall-btn {
  background-color: #ffffff;
  border-color: #305e44;
  color: #305e44;
  font-weight: 600;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 50px;
  font-size: 14px;
  height: 34px;
  padding: 5px 30px;
}

.landing-container .deco-parent-div {
  position: relative;
}

.landing-container .deco-child-div {
  z-index: 1;
  position: absolute;
}

.wedding-img-pos {
  height: 600px;
  left: 115px;
}

.sangeet-img-pos {
  left: 300px;
}

.haldi-img-pos {
  top: 370px;
  right: 786px;
}

.reception-img-pos {
  top: 240px;
  right: 93px;
}

.product-card
  :where(.css-dev-only-do-not-override-sk7ap8).ant-card
  .ant-card-body {
  padding: 18px;
  border-radius: 0 0 8px 8px;
  background: #ffffff;
  /* cursor: pointer; */
  text-align: center;
}

.product-card
  :where(.css-dev-only-do-not-override-26rdvq).ant-card
  .ant-card-meta-title {
  color: #210606;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font: normal normal 16px josefinSemiBold;
}

/*About US*/
.aboutus-box {
  padding: 0px 130px 20px 130px;
}

.about-us-parent-box {
  height: 100%;
}
.founder-dev {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  height: 85%;
}
.founder-dev img {
  margin-top: 35px;
  height: 100%;
  width: 80%;
  border-radius: 10px;
}
.founder-dev-2 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  height: 90%;
}
.founder-dev-2 img {
  margin-top: 0px;
  height: 100%;
  width: 80%;
  border-radius: 10px;
}
.about-us-description ol li {
  line-height: 30px;
}

.about-us-description {
  font: normal normal 16px josefinSans;
  text-align: left;
}

.page-title {
  font: normal normal 18px latoBold;
  text-align: left;
  margin-top: 30px;
  padding: 0 0 00px 90px;
}

.page-title-border {
  border-bottom: 3px solid #eba821;
}

.aboutus-serve-description {
  font: normal normal 16px lato;
  text-align: left;
}

.industries-serve-parent-box {
  position: relative;
  height: 150px;
  width: 60px;
  background-color: #eba821;
  border-radius: 5px;
}

.industries-serve-child-box {
  position: absolute;
  height: 170px;
  width: 300px;
  background-color: #ffff;
  border: 1px solid rgb(238, 228, 228);
  border-radius: 3px;
  /* box-shadow: 0 0 10px #fff; */
  left: 10px;
  bottom: 10px;
}

.industries-service-title {
  font: normal normal 16px latoBold;
  padding: 10px 40px;
  text-align: left;
}

.industries-serve-child-box .industries-service-dec {
  margin: 0 10px;
}

@media screen and (max-width: 380px) and (min-width: 260px) {
  .page-title {
    font: normal normal 14px latoBold;
    text-align: left;
    margin-top: 30px;
    padding: 0 0 20px 40px;
  }

  .subtitle-heading {
    font: bold 20px RomanRegular;
  }

  .industries-serve-parent-box {
    position: relative;
    /* height: 50px; */
    width: 50px;
    background-color: #eba821;
    border-radius: 5px;
  }

  .industries-service-title {
    font: normal normal 14px latoBold;
    padding: 5px 20px;
    text-align: left;
  }
}

@media screen and (max-width: 660px) and (min-width: 541px) {
  .industries-serve-child-box {
    position: absolute;
    /* height: 50px; */
    width: 220px;
    background-color: #ffff;
    border: 1px solid rgb(238, 228, 228);
    border-radius: 3px;
    /* box-shadow: 0 0 10px #fff; */
    left: 10px;
    bottom: 10px;
  }
}

@media screen and (max-width: 960px) {
  .aboutus-box {
    padding: 30px 20px 30px 20px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 940px) {
  .aboutus-box {
    padding: 30px 50px 30px 50px;
  }
}

@media screen and (max-width: 278px) {
  .aboutus-box {
    padding: 0px 0px 30px 30px;
  }
}

.contact-us-parent-div {
  position: relative;
  padding: 0px 0px 30px 85px;
}

.contact-us-child-div {
  position: absolute;
}

.contact-btn {
  width: 20%;
}
@media (max-width: 400px) {
  .contact-btn {
    width: 30%;
  }
}
.contact-btn input {
  background-color: #eba821;
  border: 1px solid#eba821;
  color: #ffffff;
  font-weight: 600;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 5px;
  font-size: 14px;
  height: 34px;
  text-align: center;
}

.products {
  padding: 50px;
}

.products .req-btn {
  background-color: #305e44;
  border-color: #305e44;
  color: #ffffff;
  font-weight: 600;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 50px;
  font-size: 14px;
  height: 34px;
  padding: 5px 60px;
}

.price-text {
  color: #210606;
}

.product-incr-decr {
  background-color: #305e44;
  border-color: #305e44;
  color: #ffffff;
  font-weight: 600;
  border-radius: 50%;
  font-size: 12px;
  padding: 8px 11px 0px 11px;
}

.product-count-box {
  background-color: #ffffff;
  border: 1.3px solid #305e44;
  color: #305e44;
  font-weight: 600;
  border-radius: 100px;
  font-size: 14px;
  padding: 5px 40px;
}

/*Cart css start*/
.small-product-image-container {
  border: none;
  border-radius: 8px;
}

.large-product-image-container {
  border: none;
  border-radius: 8px;
}

.save-text {
  color: #479167;
}

.products .more-info p {
  font: normal 14px lato;
  color: #484747;
}
/*Cart css end*/

.contact-us-parent-box {
  position: relative;
}
.contact-form-div {
  height: 100%;
  width: 100%;
}

.contact-us-child-box {
  z-index: 1;
  position: absolute;
}
/* @media (min-width: 300px) and (max-width: 700px) {
  .contact-us-child-box h3{
  
  }
} */

@media (min-width: 3500px) and (max-width: 4500px) {
  .contact-us-child-box h3 {
    width: 20%;
  }
}
@media (min-width: 2400px) and (max-width: 3499px) {
  .contact-us-child-box h3 {
    width: 30%;
  }
}
@media (min-width: 1901px) and (max-width: 2399px) {
  .contact-us-child-box h3 {
    width: 50%;
  }
}
@media (min-width: 1700px) and (max-width: 1900px) {
  .contact-us-child-box h3 {
    width: 60%;
  }
}

@media (min-width: 1441px) and (max-width: 1699px) {
  .contact-us-child-box h3 {
    width: 100%;
  }
}

@media (max-width: 1440px) {
  .contact-us-child-box h3 {
    font-size: 12px;
    width: 150%;
  }
}
@media (max-width: 1300px) {
  .contact-us-child-box h3 {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .contact-us-child-box h3 {
    font-size: 12px;
    width: 130%;
  }
}

@media (min-width: 610px) and (max-width: 767px) {
  .contact-us-child-box h3 {
    width: 100%;
  }
}
@media (min-width: 510px) and (max-width: 766px) {
  .contact-us-child-box h3 {
    width: 50%;
  }
}
@media (max-width: 400px) {
  .contact-us-child-box h3 {
    margin-top: 20px;
    font-size: 10px;
  }
}
@media (max-width: 310px) {
  .contact-us-child-box h3 {
    width: 150%;
    font-size: 10px;
  }
}
@media (min-width: 1000px) and (max-width: 2700px) {
  .text-div h3 {
    font-size: 12px;
    width: 100%;
  }
}

@media (min-width: 500px) and (max-width: 900px) {
  .text-div {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .text-div h3 {
    width: 100%;
    font-size: 13px;
  }
}
@media (max-width: 300px) {
  .text-div h3 {
    width: 100%;
    font-size: 10px;
  }
}
.address-row {
  padding: 40px 70px 20px 50px;
}
@media (min-width: 1900px) {
  .address-row {
    margin-top: 0px;
  }
}
@media (max-width: 1700px) {
  .address-row {
    margin-top: 40px;
  }
}
@media (max-width: 500px) {
  .address-row {
    font-size: 10px;
    padding: 0px 70px 20px 50px;
  }
}
.row-1 {
  padding: 0px 70px 0px 50px;
}
@media (min-width: 2500px) {
  .row-1 {
    padding: "0px 70px 0px 0px";
  }
}
@media (max-width: 1240px) {
  .value-box {
    padding: 0px 0px 0px 10px;
  }
}
@media (max-width: 750px) {
  .value-box {
    padding: 15px 0px 0px 0px;
  }
}
@media (max-width: 400px) {
  .value-box {
    padding: 5px 0px 0px 0px;
  }
}

/* Product Info css starts here */

.product-info {
  padding: 2rem 5rem 5rem 5rem;
}

.product-info-side-image-container {
  height: 6rem;
  width: 6rem;
  border-radius: 4px;
}

.product-info-main-image-container {
  height: 20.375rem;
  width: 20.375rem;
  border-radius: 4px;
}

.side-image {
  width: 100%;
  height: 6rem;
  border-radius: 4px;
}

.main-image {
  width: 100%;
  height: 20.375rem;
  border-radius: 4px;
}

.add-to-cart-btn,
.add-to-cart-btn:hover {
  background-color: #305e44;
  border-color: #305e44;
  color: #ffffff !important;
  border: none;
  font-weight: 600;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  border-radius: 50px;
  font-size: 14px;
  height: 34px;
  padding: 5px 60px;
}

.product-details {
  padding-left: 2.5rem;
}

.product-name {
  font-family: Montserrat-SemiBold;
  font-size: 1.125rem;
  color: #212121;
  margin-bottom: 1.5rem;
}

.product-desc {
  font-family: Montserrat-Regular;
  font-size: 1rem;
  color: #212121;
  line-height: 1.25rem;
  margin-bottom: 1.5rem;
}

.price-prop {
  font-family: Montserrat-Medium;
  font-size: 1rem;
  color: #212121;
}

.price-val {
  font-family: Montserrat-SemiBold;
  font-size: 1rem;
  color: #212121;
  letter-spacing: 0.015em;
  margin-left: 0.5rem;
}

.og-price-val {
  font-family: Montserrat-Regular;
  font-size: 0.875rem;
  color: #212121;
  text-decoration: line-through;
  margin-left: 1rem;
}

.save-price-div {
  margin-top: 1rem;
}

.save-price-prop {
  font-family: Montserrat-Medium;
  font-size: 1rem;
  color: #219653;
}

.save-price-val {
  font-family: Montserrat-SemiBold;
  font-size: 1rem;
  color: #219653;
  letter-spacing: 0.015em;
  margin-left: 0.5rem;
}

.inc-dec-div,
.add-to-cart-btn-div {
  margin-top: 1rem;
}

.bangalore-only-text {
  font-family: Montserrat-SemiBold;
  font-size: 1rem;
  color: #212121;
  margin-top: 1.5rem;
}

.more-info-section {
  margin-top: 2rem;
}

.more-info-head {
  font-family: Montserrat-SemiBold;
  font-size: 1.125rem;
  color: #212121;
}

.more-info-body {
  font-family: Montserrat-Regular;
  font-size: 1rem;
  color: #212121;
  margin-top: 1rem;
}

.faqs-section {
  margin-top: 2rem;
}

/* Product Info css ends here */
.ant-carousel .slick-next {
  inset-inline-end: 0px;
}

.service-container {
  width: 100%;
}

.service-container .heading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-container .heading h2 {
  margin: 0px;
}

.service-image-wrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* padding: 0px 40px; */
  flex-wrap: wrap;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
}

.service-images {
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.service-img img {
  height: inherit;
  width: 100%;
}

.service-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7aa1a;
  color: #ffffff;
  padding: 10px 0px;
  border-radius: 5px;
}

.ant-card .ant-card-meta-title {
  color: #210606;
}

.heading h2 {
  font-size: 4rem;
  margin: 0px;
}

.BWImage1,
.BWImage4 {
  display: flex;
  justify-content: center;
  height: 280px;
}
.BWImage1 img,
.BWImage4 img {
  border-radius: 10px;
  height: 100%;
}
.BWImage2,
.BWImage5 {
  margin-top: 10px;
  height: 250px;
}
.BWImage2 img,
.BWImage5 img {
  border-radius: 10px;
  height: 100%;
}

.BWImage3 {
  height: 100%;
}
.BWImage3 img {
  border-radius: 10px;
  height: 100%;
}
@media screen and (max-width: 500px) {
  .BWImage3 img {
    height: 300px;
  }
}

/* Occasion festival  */

.occasion-festival {
  background-image: url(../../assets//images/ContactUs.png);
  background-position: center;
  background-size: cover;
  /* background-repeat: no-repeat; */
  height: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 400px) {
  .occasion-div {
    padding: 0px;
  }
}
/* .occasion-festival-row{
  margin-top: 60px;
  padding: 40px;
} */

.OFImage1,
.OFImage4 {
  display: flex;
  justify-content: center;
  height: 280px;
}
.OFImage1 img,
.OFImage4 img {
  border-radius: 10px;
  height: 100%;
}
.OFImage2,
.OFImage5 {
  margin-top: 10px;
  height: 250px;
}
.OFImage2 img,
.OFImage5 img {
  border-radius: 10px;
  height: 100%;
}

.OFImage3 {
  height: 100%;
}
.OFImage3 img {
  border-radius: 10px;
  height: 100%;
}
@media screen and (max-width: 500px) {
  .OFImage3 img {
    height: 300px;
  }
}

/* services css */
.engagement-img img,
.sangeet-img img,
.haldi img,
.wedding-img img {
  border-radius: 4px;
}
@media (max-width: 500px) {
  .engagement-img img,
  .sangeet-img img,
  .wedding-img img {
    height: 393px;
  }
}

.kasturi-img {
  border-radius: 20px;
  height: 400px;
}

.kasturi-img img {
  height: 100%;
  border-radius: 20px;
}
.jayamahal-img {
  border-radius: 20px;
  height: 400px;
}

.jayamahal-img img {
  border-radius: 10px;
  height: 100%;
}
.corporate-office-img {
  border-radius: 20px;
  height: 400px;
}

.corporate-office-img img {
  border-radius: 20px;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .service-images {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .service-images {
    width: 40%;
  }
  .heading h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 425px) {
  .service-images {
    width: 100%;
  }
  .heading h2 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 770px) {
  .product-info {
    padding: 2rem;
  }

  .product-details {
    padding-left: 0.5rem;
  }
}
.carousel .slide img {
  height: 450px;
  object-fit: cover;
}

@media (max-width: 500px) {
  .carousel .slide img {
    height: 320px;
  }
}
.customer-form input {
  margin-top: 10px;
  padding: 0px 10px 0px 12px;
  border: 0.5px solid #777777;
  border-radius: 4px;
  width: 100%;
  height: 30px;
}
@media (max-width: 768px) {
  .customer-form input {
    width: 95%;
  }
}
@media (max-width: 400px) {
  .customer-form input {
    padding: 0px 0px 0px 5px;
  }
}
.customer-form input[type="text"]:focus {
  border: 0.5px solid #4a9cff;
}
.customer-form input:hover {
  border: 0.5px solid #4a9cff;
}
.customer-form-textarea {
  margin-top: 10px;
  padding: 5px 10px 0px 10px;
  border: 0.5px solid #777777;
  border-radius: 4px;
  width: 100%;
}
@media (min-width: 400px) and (max-width: 788px) {
  .customer-form-textarea {
    width: 95%;
  }
}

@media (min-width: 200px) and (max-width: 399px) {
  .customer-form-textarea {
    width: 98%;
  }
}
.customer-form-textarea::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 13px;
  font-weight: 500;
  padding: 0px 0px 0px 0px;
}
@media (max-width: 400px) {
  .customer-form-textarea::placeholder {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 13px;
    font-weight: 500;
    padding: 0px 0px 0px 5px;
  }
}

@media (max-width: 400px) {
  .customer-form-textarea {
    margin-top: 10px;
    padding: 0px 0px 0px 0px;
    border: 0.5px solid #777777;
    border-radius: 4px;
  }
}
.customer-form-textarea:hover {
  border: 0.5px solid #4a9cff;
}

.form-select {
  margin-top: 10px;
  padding: 0px 10px;
  border: 0.5px solid #777777;
  border-radius: 4px;
  width: 100%;
  height: 30px;
}
@media (min-width: 400px) and (max-width: 788px) {
  .form-select {
    width: 100%;
  }
}
@media (min-width: 200px) and (max-width: 399px) {
  .form-select {
    width: 99%;
  }
}
.form-select .placeholder {
  color: #a8a8a8;
}

.form-select option:disabled {
  color: #a8a8a8;
}

.form-select:hover {
  border: 0.5px solid #4a9cff;
}

.student-exp-input {
  display: flex;
  justify-content: start;
  align-items: center;
}
.student-exp-input input {
  width: 5%;
}
.student-exp-input p {
  padding-top: 5px;
}
.card-row {
  margin-top: 20px;
  padding: 40px;
}
.card-div {
  display: flex;
  justify-content: center;
}
@media (max-width: 400px) {
  .card-div {
    width: 100%;
    height: 100%;
  }
}
.card-btn {
  display: flex;
  justify-content: center;
  text-align: center;
}
.card-btn button {
  margin-top: 20px;
  color: white;
  background-color: #eba821;
  border: 1px solid #eba821;
}
.custom-button {
  text-align: center;
  /* width: 100%; */
  color: white !important;
  background-color: #eba821 !important;
  border: 1px solid #eba821 !important;
}
.custom-button:hover {
  width: 100%;
  color: white !important;
  background-color: #eba821 !important;
  border: 1px solid #eba821 !important;
}
.customer-button {
  /* width: 100%; */
  text-align: center !important;
  color: white !important;
  background-color: #eba821 !important;
  border: 1px solid #eba821 !important;
}
.customer-button:hover {
  width: 100%;
  color: white !important;
  background-color: #eba821 !important;
  border: 1px solid #eba821 !important;
}
.hover-card {
  transition: transform 0.3s ease;
}

.hover-card:hover {
  transform: scale(1.05);
  
}
.ant-card .ant-card-body {
  padding: 0px;
}
